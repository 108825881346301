import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import Header from '@facilities/components/Header/Header';
import RetoolWidgetContainer from '@facilities/components/RetoolWidgetContainer/RetoolWidgetContainer';
import TasksAlert from '@facilities/components/TasksAlert/TasksAlert';
import {
  REQUEST_DETAIL_WIDGET_URL,
  REQUESTS_LIST_WIDGET_URL,
} from '@facilities/constants/retoolConstants';
import { UUID_V4_REGEX } from '@facilities/constants/urlConstants';
import useFetchTasks from '@facilities/hooks/useFetchTasks';
import useGetFacility from '@facilities/hooks/useGetFacility';
import { ANALYTICS_LOG_EVENTS } from '@facilities/utils/Analytics';
import { formatRetoolWidgetUrl } from '@facilities/utils/utils-fn';
import containersStyles from '@facilities/views/containers.module.css';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import { logEvent } from '@shared/utils/Analytics';

type SelectRequestHandleDataType = { requestId: string };

enum RequestActionDataType {
  DISMISS = 'dismiss',
  RELOAD = 'reload',
  CONFIRM = 'confirm',
  CANCEL = 'cancel',
}

type RequestActionHandleDataType = { action: RequestActionDataType };

const Requests = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });
  const history = useHistory();

  const { facilityId, requestId } = useParams<{ facilityId: string; requestId: string }>();
  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);
  const { refetch: refetchTasks } = useFetchTasks(facilityId);

  const isScreenXL = useIsScreenXL();

  const initialRequestId = UUID_V4_REGEX.test(requestId) ? requestId : null;
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(initialRequestId);
  const [lastReloadAskedAt, setLastReloadAskedAt] = useState<number>(new Date().getTime());

  const shouldDisplayRequestInfo = !!selectedRequestId;

  const handleSelectRequest = ({ requestId: id }: SelectRequestHandleDataType) => {
    if (!!id) {
      setSelectedRequestId(id);
    }
  };

  const handleRequestAction = async ({ action }: RequestActionHandleDataType) => {
    switch (action) {
      case RequestActionDataType.DISMISS:
        setSelectedRequestId(null);

        break;
      case RequestActionDataType.RELOAD:
        setLastReloadAskedAt(new Date().getTime());

        break;
      case RequestActionDataType.CANCEL:
        setLastReloadAskedAt(new Date().getTime());
        setSelectedRequestId(null);

        break;
      case RequestActionDataType.CONFIRM:
        void logEvent(ANALYTICS_LOG_EVENTS.CONFIRM_TASK_CLICK);
        setLastReloadAskedAt(new Date().getTime());
        await refetchTasks();

        break;
      default:
    }
  };

  useEffect(() => {
    const currentPath = history.location.pathname;
    const requestsPath = !selectedRequestId ? `/requests` : `/requests/${selectedRequestId}`;
    const newPath = currentPath.replace(/\/requests.*/, requestsPath);

    window.history.pushState({}, '', newPath);
  }, [selectedRequestId, history.location.pathname]);

  const requestsListUrl = formatRetoolWidgetUrl(REQUESTS_LIST_WIDGET_URL, { facilityId });
  const requestsWidget = (
    <RetoolWidget
      url={requestsListUrl}
      handleData={handleSelectRequest}
      data={{ selectedId: selectedRequestId, lastReloadAskedAt }}
    />
  );

  const requestUrl = formatRetoolWidgetUrl(REQUEST_DETAIL_WIDGET_URL, {
    requestId: selectedRequestId,
    facilityId,
  });
  const requestWidget = (
    <RetoolWidget key={selectedRequestId} url={requestUrl} handleData={handleRequestAction} />
  );

  const shouldShowRequestInfoBelowRequestsList = !isScreenXL;

  const getContent = () => {
    return (
      <IonContent className="ion-padding">
        <div className={containersStyles.fullWidthFlexContainer}>
          <TasksAlert />
          <div className={containersStyles.fullHeightFlexContainer}>
            <RetoolWidgetContainer
              retoolWidget={requestsWidget}
              width={shouldDisplayRequestInfo && !shouldShowRequestInfoBelowRequestsList ? 60 : 100}
            />
            {shouldDisplayRequestInfo && !shouldShowRequestInfoBelowRequestsList && (
              <RetoolWidgetContainer retoolWidget={requestWidget} width={40} />
            )}
          </div>

          {shouldDisplayRequestInfo && shouldShowRequestInfoBelowRequestsList && (
            <div className={containersStyles.fullHeightFlexContainer}>
              <RetoolWidgetContainer retoolWidget={requestWidget} width={100} />
            </div>
          )}
        </div>
      </IonContent>
    );
  };

  return (
    <IonPage>
      <Header title={t('common.menu.requests')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && getContent()}
    </IonPage>
  );
};

export default Requests;
