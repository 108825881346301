import { useIonRouter } from '@ionic/react';
import { useMutation } from '@tanstack/react-query';

import { PunctualAvailabilitiesTimespan } from '@carers/services/FetchUserAccount';
import { CustomAxiosRequestConfig, httpService } from '@shared/httpService';

type PunctualAvailabilitiesInfo = {
  punctualAvailabilities: Date[];
  punctualAvailabilitiesFormTimespan: PunctualAvailabilitiesTimespan;
};

const patchPunctualAvailabilities = async (info: PunctualAvailabilitiesInfo) => {
  const url = '/update-punctual-availabilities';
  const config: CustomAxiosRequestConfig = {
    validationErrorMessage: 'profile.messages.errors.invalidSiret',
  };

  return httpService.patch<void>(url, info, config).then((response) => response.data);
};

const useUpdatePunctualAvailabilities = () => {
  const router = useIonRouter();

  return useMutation({
    mutationFn: patchPunctualAvailabilities,
    onSuccess() {
      router.push('/carers/profile');
    },
  });
};

export { useUpdatePunctualAvailabilities, type PunctualAvailabilitiesInfo };
