import { jwtDecode } from 'jwt-decode';

type JwtPayload = { sub: { carerId: string; userId: string } };

const getCarerId = (accessToken: string) => {
  const decodedToken = jwtDecode<JwtPayload>(accessToken);

  const { carerId } = decodedToken.sub;

  return carerId;
};

const getUserId = (accessToken: string) => {
  const decodedToken = jwtDecode<JwtPayload>(accessToken);

  const { userId } = decodedToken.sub;

  return userId;
};

export { getCarerId, getUserId };
